

  /* Form */
  .Drop{
    padding: 8px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    flex-direction: row;
  }
  
  label {
    position: relative;
    bottom: 3px;
  }
  
  input {
    width: 100%;
    height: 34px;
    padding: 5px;
    border: 1px solid black;
    border-radius: 5px;
    outline: none;
    box-sizing: border-box;
    transition: all 0.3s;
  }
  
  input:valid {
    border: 1px solid green;
  }
  
  input:invalid {
    border: 1px solid red;
  }
 
  .submit-container {
    margin-top: 30px;
  }
  